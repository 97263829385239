import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import * as s from "../components/index.module.scss"

export default () => (
  <Layout>
    <Seo title="Home" />
    <div className={s.textCenter}>
      <h1 className="text-3xl font-bold underline">Hello World!</h1>
      <p className={s.intro}>
        <b>Example pages:</b>
        <br />
        Edit <code>src/pages/index.js</code> to update this page.
      </p>
    </div>
    <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
      tempor incididunt ut labore et dolore magna aliqua. Mattis nunc sed
      blandit libero. Quisque id diam vel quam elementum pulvinar etiam non.
      Gravida dictum fusce ut placerat orci nulla pellentesque dignissim. Turpis
      tincidunt id aliquet risus feugiat. Quis lectus nulla at volutpat diam ut
      venenatis. At imperdiet dui accumsan sit amet nulla. Faucibus et molestie
      ac feugiat sed lectus vestibulum mattis ullamcorper. Id aliquet risus
      feugiat in. Lectus vestibulum mattis ullamcorper velit sed. Morbi tempus
      iaculis urna id. Tellus mauris a diam maecenas sed. Tellus molestie nunc
      non blandit massa enim nec dui nunc. Dis parturient montes nascetur
      ridiculus mus mauris. Et ligula ullamcorper malesuada proin libero nunc
      consequat. Quam elementum pulvinar etiam non quam lacus suspendisse
      faucibus. Adipiscing tristique risus nec feugiat. Aliquet enim tortor at
      auctor urna nunc id cursus metus. Sed lectus vestibulum mattis ullamcorper
      velit sed ullamcorper. Est velit egestas dui id ornare arcu odio ut. Sed
      odio morbi quis commodo odio aenean sed adipiscing. Diam sollicitudin
      tempor id eu nisl nunc mi ipsum faucibus. Ut tortor pretium viverra
      suspendisse potenti nullam ac tortor vitae. Cras tincidunt lobortis
      feugiat vivamus at augue eget arcu. Ullamcorper velit sed ullamcorper
      morbi tincidunt ornare massa. Amet porttitor eget dolor morbi non arcu.
      Quam nulla porttitor massa id neque aliquam vestibulum morbi blandit. Arcu
      bibendum at varius vel pharetra vel turpis. Lectus sit amet est placerat
      in egestas erat imperdiet sed. Vel fringilla est ullamcorper eget nulla
      facilisi etiam dignissim. Nunc id cursus metus aliquam eleifend mi in.
      Nunc vel risus commodo viverra maecenas accumsan. Turpis cursus in hac
      habitasse platea dictumst. Leo integer malesuada nunc vel risus commodo.
      Ac tortor dignissim convallis aenean et tortor at. Quam lacus suspendisse
      faucibus interdum posuere lorem ipsum dolor sit. Ac tortor dignissim
      convallis aenean. Dignissim suspendisse in est ante in. Sociis natoque
      penatibus et magnis dis. Arcu non odio euismod lacinia at. Tellus mauris a
      diam maecenas. Orci a scelerisque purus semper eget duis at tellus. Ipsum
      suspendisse ultrices gravida dictum. Tristique senectus et netus et
      malesuada fames ac turpis egestas. In nibh mauris cursus mattis molestie a
      iaculis at erat. Orci sagittis eu volutpat odio facilisis mauris sit amet
      massa. Viverra adipiscing at in tellus integer feugiat scelerisque. Mi
      proin sed libero enim sed faucibus turpis in. In fermentum et sollicitudin
      ac orci phasellus. Diam maecenas ultricies mi eget mauris. Risus nullam
      eget felis eget nunc. Egestas diam in arcu cursus euismod quis viverra.
      Leo in vitae turpis massa. Libero enim sed faucibus turpis in eu. Morbi
      enim nunc faucibus a pellentesque sit amet. Id aliquet lectus proin nibh
      nisl condimentum id venenatis. Sapien eget mi proin sed libero enim sed
      faucibus. Morbi blandit cursus risus at ultrices mi tempus. Nullam ac
      tortor vitae purus. Integer malesuada nunc vel risus commodo viverra.
      Faucibus et molestie ac feugiat. Aliquam sem fringilla ut morbi tincidunt
      augue interdum. Cras fermentum odio eu feugiat pretium nibh ipsum. Integer
      malesuada nunc vel risus commodo. Sagittis aliquam malesuada bibendum arcu
      vitae. Augue ut lectus arcu bibendum. Molestie a iaculis at erat
      pellentesque. Dictum varius duis at consectetur lorem. Integer eget
      aliquet nibh praesent tristique magna sit amet purus. Cras adipiscing enim
      eu turpis egestas pretium aenean. Posuere morbi leo urna molestie at
      elementum eu facilisis. Imperdiet dui accumsan sit amet nulla. Arcu ac
      tortor dignissim convallis aenean et tortor at risus. Turpis tincidunt id
      aliquet risus feugiat in ante metus. Mollis aliquam ut porttitor leo a. Id
      nibh tortor id aliquet lectus proin. Potenti nullam ac tortor vitae purus.
      Id semper risus in hendrerit gravida rutrum quisque.
    </p>
  </Layout>
)
